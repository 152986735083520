import OverviewPlants from "../../Images/Farm_Photos/OverviewPlants.jpeg";
import PottedMums from "../../Images/Farm_Photos/PottedMums.jpeg";
import TreeLine from "../../Images/Farm_Photos/TreeLine.jpeg";
import React, { useState } from "react";
import { Box, Grid2, ToggleButtonGroup, Typography } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import DropdownList from "../../Components/DropdownList";
import { Helmet } from "react-helmet";

const Home = () => {
	const [selectedTab, setSelectedTab] = useState("flowers");

	const handleTabChange = (event, newValue) => {
		setSelectedTab(newValue);
	};

	const flowers = [
		{ name: "Amaranthus", type: "Flower", description: "Red Spike" },
		{ name: "Artemisia", type: "Flower", description: "Sweet Annie Organic" },
		{ name: "Asclepias", type: "Flower", description: "Apollo Orange" },
		{ name: "Aster", type: "Flower", description: "Tower White" },
		{ name: "Aster", type: "Flower", description: "Lady Coral Lavender" },
		{ name: "Aster", type: "Flower", description: "Bonita Shell Pink Film" },
		{ name: "Begonia", type: "Flower", description: "Hula Red" },
		{ name: "Celosia", type: "Flower", description: "Sunday Bright Pink" },
		{ name: "Celosia", type: "Flower", description: "Sunday Gold" },
		{ name: "Coleus", type: "Flower", description: "Chocolate Mint" },
		{
			name: "Cynoglossum(forget-me-not)",
			type: "Flower",
			description: "Firmament",
		},
		{ name: "Daisy", type: "Flower", description: "Denver Daisy" },
		{ name: "Daisy", type: "Flower", description: "Primadonna Deep Rose" },
		{ name: "Daisy", type: "Flower", description: "Crazy Daisy" },
		{
			name: "Delphinium",
			type: "Flower",
			description: "Magic Fountains Lilac Pink White Bee",
		},
		{ name: "Dianthus", type: "Flower", description: "Amazon Neon Duo" },
		{ name: "Dianthus", type: "Flower", description: "Marie Chabaud" },
		{
			name: "Dianthus",
			type: "Flower",
			description: "Chabaud Orange Sherbert",
		},
		{ name: "Eucalyptus", type: "Flower", description: "Silver Dollar" },
		{
			name: "Geranium",
			type: "Flower",
			description: "Pinto Premium White/Rose",
		},
		{ name: "Geranium", type: "Flower", description: "Maverick Orange" },
		{ name: "Geranium", type: "Flower", description: "Pinto Premium White" },
		{ name: "Geranium", type: "Flower", description: "Pinto Premium Violet" },
		{ name: "Geranium", type: "Flower", description: "Pinto Premium Deep Red" },
		{ name: "Hibiscus", type: "Flower", description: "Mahogany Splendor" },
		{
			name: "Impatiens",
			type: "Flower",
			description: "Solarscape Orange Burst",
		},
		{ name: "Impatiens", type: "Flower", description: "New Guinea Lavender" },
		{ name: "Impatiens", type: "Flower", description: "Imara XDR White" },
		{ name: "Impatiens", type: "Flower", description: "Beacon Lipstick" },
		{ name: "Impatiens", type: "Flower", description: "Beacon Coral" },
		{ name: "Marigold", type: "Flower", description: "Queen Sophia" },
		{ name: "Marigold", type: "Flower", description: "Nosento Limegreen" },
		{ name: "Marigold", type: "Flower", description: "Giant Orange" },
		{ name: "Pansy", type: "Flower", description: "Cool Wave Raspberry" },
		{ name: "Pansy", type: "Flower", description: "Atlas Black" },
		{ name: "Petunia", type: "Flower", description: "Dreams Midnight" },
		{ name: "Petunia", type: "Flower", description: "Daddy Blue" },
		{ name: "Snapdragon", type: "Flower", description: "Avignon Deep Rose" },
		{ name: "Snapdragon", type: "Flower", description: "Potomac Yellow" },
		{ name: "Snapdragon", type: "Flower", description: "Potomac Royal" },
		{ name: "Snapdragon", type: "Flower", description: "Orleans Early Yellow" },
		{ name: "Sunflower", type: "Flower", description: "Sunrich Lemon" },
		{ name: "Viola", type: "Flower", description: "Sorbet XP" },
		{ name: "Viola", type: "Flower", description: "Angel Tiger Eye" },
		{ name: "Zinnia", type: "Flower", description: "Macarenia" },
		{ name: "Ageratum", type: "Flower", description: "Mix Timeless Mix" },
		{ name: "Begonia", type: "Flower", description: "Mix Ambassador Mix" },
		{ name: "Celosia", type: "Flower", description: "Mix Sunday Mix" },
		{
			name: "Dianthus",
			type: "Flower",
			description: "Mix Chabaud Picotee Double Mix",
		},
		{ name: "Digitalis", type: "Flower", description: "Mix Camelot Mix" },
		{ name: "Viola", type: "Flower", description: "Mix Sorbet Formula Mix" },
		{ name: "Viola", type: "Flower", description: "Mix Penny All Season Mix" },
	];
	const fruits = [
		{ name: "Squash", type: "Fruit", description: "ButterBaby" },
		{ name: "Pumpkin", type: "Fruit", description: "New England Pie Organic" },
		{ name: "Honey-do", type: "Melon", description: "Sugar Rush" },
		{ name: "Watermelon", type: "Melon", description: "Crimson Sweet" },
	];

	const veggies = [
		{ name: "Basil", type: "Herb", description: "Sweet Thai" },
		{ name: "Chamomile", type: "Herb", description: "German" },
		{ name: "Cilantro", type: "Herb", description: "Marino Organic" },
		{ name: "Cilantro", type: "Herb", description: "Cruiser" },
		{ name: "Dill", type: "Herb", description: "Bouquet Organic" },
		{ name: "Lemon Grass", type: "Herb", description: "East Indian" },
		{ name: "Oregano", type: "Herb", description: "Vulgare" },
		{ name: "Parsley", type: "Herb", description: "Giant of Italy" },
		{ name: "Rosemary", type: "Herb", description: "Rosemary" },
		{ name: "Thyme", type: "Herb", description: "German" },
		{ name: "Bean", type: "Vegetable", description: "Provider" },
		{ name: "Bean", type: "Vegetable", description: "Jade" },
		{ name: "Broccoli", type: "Vegetable", description: "Belstar Organic" },
		{ name: "Broccoli", type: "Vegetable", description: "Green Magic" },
		{ name: "Cabbage - Green", type: "Vegetable", description: "Boltran" },
		{
			name: "Cabbage - Red",
			type: "Vegetable",
			description: "Ruby Perfection",
		},
		{ name: "Carrot", type: "Vegetable", description: "Narvik" },
		{ name: "Carrot", type: "Vegetable", description: "Mokum" },
		{ name: "Celery", type: "Vegetable", description: "Kelvin" },
		{ name: "Cucumber", type: "Vegetable", description: "Dasher" },
		{ name: "Cucumber", type: "Vegetable", description: "Unagi" },
		{
			name: "Cucumber",
			type: "Vegetable",
			description: "Marketmore 76 organic",
		},
		{
			name: "Cucumber(pickle)",
			type: "Vegetable",
			description: "H-19 Little Leaf Organic",
		},
		{ name: "Lettuce", type: "Vegetable", description: "Sunland" },
		{ name: "Lettuce", type: "Vegetable", description: "Winter Density" },
		{ name: "Onion - Red", type: "Vegetable", description: "Cabernet" },
		{ name: "Onion - Spring", type: "Vegetable", description: "Feast" },
		{
			name: "Onion - Spring",
			type: "Vegetable",
			description: "Parade organic",
		},
		{ name: "Onion - White", type: "Vegetable", description: "White Castle" },
		{ name: "Onion - Yellow", type: "Vegetable", description: "Patterson" },
		{ name: "Pea", type: "Vegetable", description: "Sugar Snap" },
		{ name: "Pepper", type: "Vegetable", description: "Cornito Organic" },
		{ name: "Pepper", type: "Vegetable", description: "Lunchbox" },
		{ name: "Pepper", type: "Vegetable", description: "Jalapeño" },
		{ name: "Pepper - Green Bell", type: "Vegetable", description: "Classic" },
		{ name: "Pepper - Red Bell", type: "Vegetable", description: "Sprinter" },
		{
			name: "Pepper - Red Bell",
			type: "Vegetable",
			description: "King Arthur",
		},
		{
			name: "Pepper - Yellow Bell",
			type: "Vegetable",
			description: "Sweet Sunrise organic",
		},
		{ name: "Sweet Corn", type: "Vegetable", description: "Solstice" },
		{ name: "Tomato", type: "Vegetable", description: "Supersweet" },
		{ name: "Tomato", type: "Vegetable", description: "San Marzano" },
		{ name: "Tomato", type: "Vegetable", description: "Monica" },
	];

	const groupedFlowers = flowers.reduce((acc, flower) => {
		if (!acc[flower.name]) {
			acc[flower.name] = [];
		}
		acc[flower.name].push(flower);
		return acc;
	}, {});

	// Sort the grouped flowers alphabetically
	const sortedGroupedFlowers = Object.keys(groupedFlowers)
		.sort()
		.reduce((acc, key) => {
			acc[key] = groupedFlowers[key];
			return acc;
		}, {});

	const groupedFruits = fruits.reduce((acc, fruit) => {
		if (!acc[fruit.name]) {
			acc[fruit.name] = [];
		}
		acc[fruit.name].push(fruit);
		return acc;
	}, {});

	// Sort the grouped fruits alphabetically
	const sortedGroupedFruits = Object.keys(groupedFruits)
		.sort()
		.reduce((acc, key) => {
			acc[key] = groupedFruits[key];
			return acc;
		}, {});

	const groupedVeggies = veggies.reduce((acc, veggie) => {
		if (!acc[veggie.name]) {
			acc[veggie.name] = [];
		}
		acc[veggie.name].push(veggie);
		return acc;
	}, {});

	// Sort the grouped veggies alphabetically
	const sortedGroupedVeggies = Object.keys(groupedVeggies)
		.sort()
		.reduce((acc, key) => {
			acc[key] = groupedVeggies[key];
			return acc;
		}, {});

	return (
		<Box>
			<Helmet>
				<meta
					name="description"
					content="Gosell Gardens is a family-owned farm located in Inver Grove Heights, MN. We offer a wide variety of annuals, perrenials, trees, shrubs and produce for sale. Our farm is open to the public during the growing season."
				/>
				<meta name="title" content="Gosell Gardens"></meta>

				<meta property="og:title" content="Gosell Gardens" />
				<meta
					property="og:description"
					content="Gosell Gardens is a family-owned farm located in Inver Grove Heights, MN. We offer a wide variety of annuals, perrenials, trees, shrubs and produce for sale. Our farm is open to the public during the growing season."
				/>
				<meta property="og:url" content="https://gosellgardens.com" />
				<meta property="og:type" content="website" />
				<title>Gosell Gardens</title>

				<link rel="canonical" href="https://gosellgardens.com" />
			</Helmet>
			<Box
				padding={2}
				backgroundColor={
					//light red
					"#FFC0CB"
				}
			>
				<Typography variant="h4" align="center">
					Opening ~ May 1st 2025
				</Typography>
			</Box>
			<Box padding={2}>
				<Typography
					variant="h1"
					align="center"
					sx={{
						color: "primary",
						fontWeight: "bold",
						fontSize: "2.5rem",
						mt: 2,
						mb: 2,
					}}
				>
					Welcome to Gosell Gardens: Your friendly neighborhood farm
				</Typography>
				<Typography variant="body1" align="center">
					We are a family-owned farm located in Inver Grove Heights, MN. We
					offer a wide variety of annuals, perrenials, trees, shrubs and produce
					for sale. Our farm is open to the public during the growing season. We
					look forward to seeing you soon!
				</Typography>
			</Box>
			<Box padding={2}>
				<Grid2 container spacing={2} sx={{ mt: 4 }}>
					<Grid2 size={{ xs: 12, md: 6 }}>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "left",
								alignItems: "left",
								padding: "2px",
							}}
						>
							{/* Google Map */}
							{/* Replace the iframe src with your own Google Map embed code */}
							<iframe
								title="Farm Location"
								src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2827.518708729717!2d-93.0633!3d44.8488!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87f7c8e6e6e6e6e6%3A0x6d6d6d6d6d6d6d6d!2s9226%20Old%20Concord%20Blvd%2C%20Inver%20Grove%20Heights%2C%20MN%2055076!5e0!3m2!1sen!2sus!4v1635432100000!5m2!1sen!2sus"
								width="100%"
								height="400"
								style={{ border: 0 }}
								allowFullScreen=""
								loading="lazy"
							></iframe>
							<Box>
								<Typography variant="h6" sx={{ mb: 2 }}>
									Contact Us:
								</Typography>
								<Typography variant="body1" sx={{ mb: 2 }}>
									Phone: 651-239-4444
								</Typography>
								<Typography variant="body1" sx={{ mb: 2 }}>
									Email: connor@gosellgardens.com (Connor - Farm Manager)
								</Typography>
								<Typography variant="body1" sx={{ mb: 2 }}>
									In season hours typically Wednesday to Saturday 7am - 2pm,
									Sunday at the IGH Farmers market (TBD) and by appointment. We
									also have a 24/7 produce stand on site later in the season.
								</Typography>
							</Box>
							<Box>
								<Typography variant="h6" sx={{ mb: 2 }}>
									Farm Gallery:
								</Typography>
								<Grid2 container spacing={2}>
									<Grid2 size={{ xs: 6, md: 4 }}>
										{/* Add your farm photos here */}
										<img
											src={OverviewPlants}
											alt="Overview of Plants"
											style={{ width: "100%" }}
										/>
									</Grid2>
									<Grid2 size={{ xs: 6, md: 4 }}>
										{/* Add your farm photos here */}
										<img
											src={PottedMums}
											alt="Potted Mums"
											style={{ width: "100%" }}
										/>
									</Grid2>
									<Grid2 size={{ xs: 6, md: 4 }}>
										{/* Add your farm photos here */}
										<img
											src={TreeLine}
											alt="Tree Line"
											style={{ width: "100%" }}
										/>
									</Grid2>
									{/* Add more farm photos as needed */}
								</Grid2>
							</Box>
						</Box>
					</Grid2>

					<Grid2 size={{ xs: 12, md: 6 }}>
						<Grid2 container spacing={2}>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "center",
									width: "100%",

									padding: "2px",
								}}
							>
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
										alignItems: "center",
										width: "100%",
										padding: "2px",
									}}
								>
									<Typography variant="h6" sx={{ mb: 2 }}>
										Our Plants
									</Typography>
									<Typography variant="body2" sx={{ mb: 2 }}>
										We have a variety of plants planned for 2025. We will also
										have a variety of harvested produce available for purchase
										later in the season.
									</Typography>
									<Typography variant="body2" sx={{ mb: 2 }}>
										Please select a category to view the varieties. We will be
										offering a wide selection of flowers, fruits, veggies, and
										herbs for purchase as well as a variety of potted plants,
										trees and shrubs. We'll also have a selection of hanging
										baskets to choose from.
									</Typography>
								</Box>

								<ToggleButtonGroup
									exclusive
									value={selectedTab}
									onChange={handleTabChange}
								>
									<ToggleButton value="flowers">Flowers</ToggleButton>
									<ToggleButton value="fruits">Fruits</ToggleButton>
									<ToggleButton value="veggies">Veggies</ToggleButton>
									<ToggleButton value="eggs">Eggs</ToggleButton>
								</ToggleButtonGroup>
								{selectedTab === "flowers" && (
									<DropdownList title="Flowers" items={sortedGroupedFlowers} />
								)}
								{selectedTab === "fruits" && (
									<DropdownList title="Fruits" items={sortedGroupedFruits} />
								)}
								{selectedTab === "veggies" && (
									<DropdownList title="Veggies" items={sortedGroupedVeggies} />
								)}
								{selectedTab === "eggs" && (
									<Typography variant="body2" sx={{ mb: 2 }}>
										Farm fresh brown eggs available ~Late June/ or Early July
										2025!
									</Typography>
								)}
							</Box>
						</Grid2>
					</Grid2>
				</Grid2>
			</Box>
		</Box>
	);
};

export default Home;
