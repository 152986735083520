import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { ArrowDropDown, Circle } from "@mui/icons-material";

const DropdownList = ({ items }) => {
	const [openDropdowns, setOpenDropdowns] = useState({});

	const toggleDropdown = (type) => {
		setOpenDropdowns((prev) => ({
			...prev,
			[type]: !prev[type],
		}));
	};

	if (!items) {
		return null;
	}

	return (
		<Box width={"100%"}>
			{Object.keys(items).map((type) => (
				<Box
					key={type}
					//sx hover effect
					sx={{
						mb: 1,
						"&:hover": {
							cursor: "pointer",
						},
					}}
					onClick={() => toggleDropdown(type)}
				>
					<Box
						sx={{
							display: "flex",
							width: "100%",
							padding: "2px",
							justifyContent: "space-between",
						}}
					>
						<Typography variant="h6">{type}</Typography>

						<ArrowDropDown
							sx={{
								height: "100%",
							}}
						/>
					</Box>
					{openDropdowns[type] && (
						<Box paddingLeft={5}>
							{items[type].map((item) => (
								<Box key={item.name}>
									<Circle
										sx={{
											height: "10px",
											width: "10px",
											paddingRight: "2px",
										}}
									/>

									{item.description}
								</Box>
							))}
						</Box>
					)}
				</Box>
			))}
		</Box>
	);
};

export default DropdownList;
